export const ListType = {
  ORGANIZATION: 1,
  MEMBER: 2,
  DEMO: 3,
  TEMPLATES: 4,
};

export const UserType = {
  ADMIN: 'admin',
  ORG: 'organization',
  USER: 'user',
  TEST: 'test',
};

export const UserStatusType = {
  INVITED: 'invited',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  SUSPENDED: 'suspended',
};

export const DummyPassword = 'Integra@123';

export const DocumentUserStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINE: 'decline',
};

export const PlatformIntegration = {
  JWT: 'docusign_jwt_grant',
  IMPLICIT: 'docusign_implicit_grant',
  DEFAULT: 'default',
};

export const DOCUSIGN_KEY = 'docusignData';
export const STREAM_REALTY_APP = 'Stream Realty Contract Tokenization';

export const NotificationType = {
  DEFAULT: 1,
  REQUEST: 2,
  MESSAGE: 3,
  MESSAGE_ATTACHMENT: 4,
  JSON_MESSAGE: 5,
  TRANSFER: 6,
};
export const FileAgreementType = {
  MAIN: 'Main / Master Agreement',
  SUB: 'Sub-Agreement',
  AMENDMENT: 'Amendment',
  SUPPORTING: 'Supporting Document',
};

export const AgreementType = {
  COLLABORATION: 'Collaboration',
  GENERAL: 'General',
  LICENSE_LOAN: 'License / Loan',
  MICROSOFT_PROGRAM: 'Microsoft Program',
  PATENT_TRANSACTIONS: 'Patent Transactions',
  PROCUREMENT: 'Procurement',
  SALES: 'Sales',
  SETTLEMENT: 'Settlement',
  SIG_STANDARD_FOUNDATION: 'SIG / Standard / Foundation',
};

export const AgreementSubType = {
  MISC: 'Miscellaneous',
  INBOUND: 'Inbound',
  OUTBOUND: 'Outbound',
  RECIPROCAL: 'Reciprocal',
  LEGACY_MICROSOFT_CITA: 'Legacy Microsoft CITA (A-C-S)',
  MICROSOFT_MDLA_DA: 'Microsoft MDLA DA',
  PATENT_CROSS_LICENSE: 'Patent Cross License or Bilateral Non-assert',
  PATENT_LICENSE_INBOUND: 'Patent License (Inbound) or Unilateral Non-assert',
  PATENT_LICENSE_OUTBOUND: 'Patent License (Outbound) or Unilateral Non-assert',
  PATENT_SETTLEMENT: 'Patent Settlement',
  PATENT_TRANSFER_BUY: 'Patent Transfer (Buy)',
  PATENT_TRANSFER_SELL: 'Patent Transfer (Sell)',
  GOODS: 'Goods',
  SERVICES: 'Services',
};

export const BusinessUnitType = {
  ADB: 'ADB (82398)',
  CCG_CPE_WCS_WWAN_PSW_WIFIDRV: 'CCG CPE WCS WWAN PSW WIFIDRV (103531)',
  CEO_TA: 'CEO TA (99253)',
  CLIENT_COMPUTING_GROUP: 'CLIENT COMPUTING GROUP (11574)',
  CORPORATE_STRATEGY_AND_VENTURES: 'Corporate Strategy & Ventures (36124)',
  CPM_OPS_LVLFIVE: 'CPM OPS LVLFIVE',
  DATACENTER_AND_AI_GROUP: 'Datacenter and AI Group (59634)',
  DESIGN_ENGINEERING_GROUP: 'Design Engineering Group (102341)',
  FINANCE: 'FINANCE (62838)',
  GLOBAL_M_AND_A: 'Global M&A (33229)',
  HR_OPERATIONS: 'HR OPERATIONS (44251)',
  INTEL_FOUNDRY_SERVICES: 'Intel Foundry Services (101318)',
  LEGAL_TRADE_AND_GOVT: 'Legal Trade & Govt (LTG) (01582)',
  MFG_SUPPLY_CHAIN_OPERATIONS: 'Mfg, Supply Chain, Operations (40634)',
  MOBILEYE: 'Mobileye (01543)',
  NETWORK_AND_EDGE_GROUP: 'Network and Edge Group (102204)',
  NSWE_SWS_CSMO: 'NSWE SWS CSMO (02423)',
  SMG: 'SMG (15563)',
  SOFTWARE_AND_ADVANCED_TECHNOLOGY: 'Software & Advanced Technology (15421)',
  TECHNOLOGY_DEVELOPMENT: 'Technology Development (14690)',
};

export const OTP_LENGTH = 4;
export const STORAGE_KEY = 'authToken';

export const MEMBER_STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: UserStatusType.ACTIVE, label: 'Active' },
  { value: UserStatusType.INACTIVE, label: 'Inactive' },
  { value: UserStatusType.INVITED, label: 'Invited' },
  { value: UserStatusType.SUSPENDED, label: 'Suspended' },
];

export const TABLE_HEAD_MEM = [
  { id: 'name', label: 'Name' },
  { id: 'organization_name', label: 'Organization', width: '100%' },
  { id: 'role', label: 'Role', width: 100 },
  { id: 'status', label: 'Status', width: 100 },
  { id: '', width: 88 },
];
export const TABLE_HEAD_ORG = [
  { id: 'organization_image', label: 'Organization' },
  { id: 'organization_name', label: 'Organization Name', width: 360 },
  { id: 'organization_url', label: 'Organization URL', width: 240 },
  { id: '', width: 88 },
];
export const TABLE_HEAD_DEMO = [
  { id: 'name', label: 'Name' },
  { id: 'logo', label: 'Logo', width: 300 },
  { id: 'pdf_image', label: 'PDF Image', width: 300 },
  { id: 'is_default', label: 'Status', width: 180 },
  { id: '', label: 'Action', width: 88 },
];

export const TABLE_HEAD_TEMPLATE = [
  { id: 'name', label: 'Name', width: 180 },
  { id: 'subject', label: 'Subject', width: 180 },
  { id: 'content', label: 'Content', width: 400 },
  { id: 'template_type', label: 'Template Type', width: 180 },
  { id: 'created_at', label: 'Created At', width: 100 },
  { id: 'is_active', label: 'Active', width: 100 },
  { id: '', label: 'Action', width: 88 },
];

export const TemplateType = {
  REGISTER_USER_TEMPLATE: 1,
  TRANSFER_TEMPLATE: 2,
  DOCUSIGN_SIGNING_TEMPLATE: 3,
};

export const MY_CREDENTIAL_TYPE = [
  { id: 'phoneNumber', label: 'Phone', icon: 'line-md:phone-call' },
  { id: 'email', label: 'Email', icon: 'line-md:email-opened' },
  { id: 'prove', label: 'Prove', icon: 'line-md:confirm-circle' },
];

export const GET_CREDENTIAL_TYPE = [
  { id: 'phoneNumber', label: 'Phone', icon: 'line-md:phone-call' },
  { id: 'email', label: 'Email', icon: 'line-md:email-opened' },
  { id: 'email', label: 'Email2', icon: 'line-md:email-opened' },
  { id: 'email', label: 'Email3', icon: 'line-md:email-opened' },
  { id: 'prove', label: 'Prove', icon: 'line-md:confirm-circle' },
  { id: 'prove', label: 'Prove2', icon: 'line-md:confirm-circle' },
  { id: 'prove', label: 'Prove3', icon: 'line-md:confirm-circle' },
  { id: 'prove', label: 'Prove4', icon: 'line-md:confirm-circle' },
];

export const CredentialsType = {
  EMAIL: 1,
  PHONE: 2,
};

export const TransferOwnerShipType = {
  ACCEPT: 1,
  DECLINE: 2,
  REQUEST_MORE: 3,
  SHARED_VC: 4,
};

export const mockDnsApprovedData = {
  integra_id: '8627948e-b1db-49ff-acaf-c4025ff9ca5e',
  organization_url: 'www.debutinfotech.com',
  organization_guid: 'b45d7f2d-aef4-4c30-8266-4d23d91bbb98',
  comm_endpoint:
    'https://sb-container-dev.orangeflower-6613dbff.westus2.azurecontainerapps.io/comms/8627948e-b1db-49ff-acaf-c4025ff9ca5e',
};

export const ignoredDocMetadata = [
  'infoJSON',
  'formJSON',
  'metadata',
  'Creator',
  'CreatorCreator',
  'CreationDate',
  'ModDate',
  'Keywords',
  'Author',
  'Title',
  'Producer',
];

export const JSONViewType = {
  GRID: 'grid',
  JSON: 'json',
};

export const JSONViewTabs = [
  {
    label: 'Grid View',
    value: JSONViewType.GRID,
  },
  {
    label: 'JSON View',
    value: JSONViewType.JSON,
  },
];

export const dummyDNSLoadingSteps = ['Looking up DNS', 'Verifying DNS', 'Verifying Identity'];

export const BLOCKCHAIN_NET_TYPE = {
  MAIN_NET: 'Mainnet',
  TEST_NET: 'Testnet',
};

export const SCHEMA_TYPE_KEY = {
  DOC_SCHEMA: 'document_schema',
  DOC_ACTIONS: 'document_actions',
};

export const SCHEMA_TYPE_ARRAY = [
  { label: 'Document Schema', value: SCHEMA_TYPE_KEY.DOC_SCHEMA },
  { label: 'Document Actions', value: SCHEMA_TYPE_KEY.DOC_ACTIONS },
];

export const LLM_PERMISSION = {
  NONE: 'None',
  REQUEST: 'Request',
  OPEN: 'Open',
};

export const MESSAGES_TYPE = {
  IMAGE: 'image',
  PDF: 'pdf',
  TEXT: 'text',
  OBJECT: 'object',
  LLM_REQUEST: 'llm_request',
};
