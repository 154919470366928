import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hooks';
//
import { useEffect } from 'react';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import MobileScreenView from 'src/sections/error/mobile-screen-view';
import Footer from './footer';
import Header from './header';

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  const pathname = usePathname();
  const isMobile = useIsMobile();

  const isHome = pathname === '/';

  useEffect(() => {
    window.Intercom('update');
  }, [children]);

  return isMobile ? (
    <MobileScreenView />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 10 },
          }),
        }}
      >
        {children}
      </Box>

      <Footer />
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
