import { DOCUSIGN_KEY } from 'src/config/constants';

const clearLocalStorage = () => {
  localStorage.removeItem(DOCUSIGN_KEY);
};
export const checkDocusignSession = () => {
  const storedData = JSON.parse(localStorage.getItem(DOCUSIGN_KEY));
  if (storedData) {
    const { start_time, expires_in, expiry_time } = storedData.docusign_auth;

    const currentTimeUTC = new Date();
    const expiryTimeUTC = new Date(expiry_time);
    if (currentTimeUTC > expiryTimeUTC) {
      console.log('Token has expired. Logging out.');
      clearLocalStorage();
    } else {
      console.log('Token is still valid.');
    }

    // const currentTime = Math.floor(Date.now() / 1000);
    // if (currentTime - start_time > expires_in) {
    //   console.log('Session Expired');
    //   clearLocalStorage();
    // } else {
    //   console.log('Session Continues');
    // }
  }
};
