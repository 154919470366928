import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/hooks';
import messages from 'src/config/messages';
import { paths } from 'src/routes/paths';
import auth_axios, { authendpoints } from 'src/utils/authAxios';
import switchboard_axiosInstance, { endpoints } from 'src/utils/switchboardAxios';
import {
  CLEAR_SELECTED_FORM,
  CREATE_FORM_SUCCESS,
  GET_ORG,
  SET_ALL_FORMS,
  SET_ALL_SCHEMA_FORMS,
  SET_ORG,
  SET_ORG_MEMBERS,
  SET_SELECTED_FORM,
  SET_SINGLE_ORG,
} from './storeActions';

export const AdminContext = React.createContext({
  allforms: [],
  allSchemaforms: [],
  selected_form: null,
  orgData: [],
  post_org: async () => {},
  get_org: async () => {},
  get_single_org: async () => {},
  patch_single_org: async () => {},
  delete_org: async () => {},
  singleOrgData: [],
  set_user_org: async () => {},
  get_org_members: async () => {},
  orgMembersList: [],
  add_org_members: async () => {},
  edit_org_member: async () => {},
  toggle_blockchain_server: async () => {},
  create_document_actions: async () => {},
  update_document_actions: async () => {},
});

function adminReducer(state, action) {
  switch (action.type) {
    case CREATE_FORM_SUCCESS:
      return state;

    case SET_ALL_FORMS:
      return {
        ...state,
        allforms: action.payload,
      };
    case SET_ALL_SCHEMA_FORMS:
      return {
        ...state,
        allSchemaforms: action.payload,
      };

    case SET_SELECTED_FORM:
      return {
        ...state,
        selected_form: action.payload,
      };

    case CLEAR_SELECTED_FORM:
      return {
        ...state,
        selected_form: null,
      };
    // case SET_ORG:
    //   return {
    //     ...state,
    //     selected_form: null,
    //   };
    case GET_ORG:
      return {
        ...state,
        orgData: action.payload,
      };
    case SET_SINGLE_ORG:
      return {
        ...state,
        singleOrgData: action.payload,
      };
    case SET_ORG_MEMBERS:
      return {
        ...state,
        orgMembersList: action.payload,
      };

    default:
      return state;
  }
}

function AdminContextProvider({ children }) {
  const [state, dispatch] = useReducer(adminReducer, {
    allforms: [],
    allSchemaforms: [],
    selected_form: null,
    orgData: [],
    singleOrgData: [],
  });
  // const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { initialize } = useAuthContext();

  // clear selected form
  const clear_selcted_form = useCallback(async () => {
    dispatch({
      type: CLEAR_SELECTED_FORM,
    });
  }, []);

  // Create Form
  const create_form = useCallback(async (form_name, form_fields) => {
    const formdata = new FormData();
    formdata.append('form_name', form_name);
    formdata.append('form_fields', form_fields);
    formdata.append('switchboard_id', '67890');

    const response = await switchboard_axiosInstance.post(endpoints.admin.insert, formdata);

    return response.data;
  }, []);

  // Fetch all forms
  const fetch_all_forms = useCallback(async () => {
    const url = `${endpoints.admin.all_forms}?switchboard_id=67890&filter=`;

    const response = await switchboard_axiosInstance.get(url);

    if (response.data) {
      if (response.data.query) {
        dispatch({
          type: SET_ALL_FORMS,
          payload: response.data.query,
        });
      } else {
        dispatch({
          type: SET_ALL_FORMS,
          payload: [],
        });
      }
    } else {
      dispatch({
        type: SET_ALL_FORMS,
        payload: [],
      });
    }
  }, []);

  // Fetch selected form
  const fetch_selected_form = useCallback(
    async (form_id) => {
      await clear_selcted_form();
      const url = `${endpoints.admin.get_form}?form_id=${form_id}`;

      const response = await switchboard_axiosInstance.get(url);

      if (response.data) {
        if (response.data.result) {
          dispatch({
            type: SET_SELECTED_FORM,
            payload: {
              form_id: 7,
              form_name: 'form1',
              form_fields: [
                {
                  section_name: 'section 1',
                  section_items: ' "q1" , "q2" , "q3" , "q4" ',
                },
                {
                  section_name: 'section 2',
                  section_items: ' "q1"',
                },
              ],
              switchboard_id: '67890',
            },
          });
        } else {
          await clear_selcted_form();
        }
      } else {
        await clear_selcted_form();
      }
    },
    [clear_selcted_form]
  );

  const post_org = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.organization, payload);
      dispatch({
        type: SET_ORG,
        payload: response.data,
      });
      navigate(paths.dashboard.user.account);
    } catch (error) {
      throw new Error(error);
      // console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_org = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.organization);
      dispatch({
        type: GET_ORG,
        payload: response.data,
      });
    } catch (error) {
      // throw new Error(error);
      console.error(error);
    }
  }, []);
  const get_single_org = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.organization}/${payload}`);
      dispatch({
        type: SET_SINGLE_ORG,
        payload: response.data,
      });
    } catch (error) {
      throw new Error(error);
      // console.error(error);
    }
  }, []);
  const patch_single_org = useCallback(async (payload, id) => {
    try {
      const response = await auth_axios.patch(
        `${authendpoints.authnew.organization}/${id}`,
        payload
      );
      enqueueSnackbar('Organization info updated successfully', { variant: 'success' });
    } catch (error) {
      throw new Error(error);
      // console.error(error);
    }
  }, []);
  const delete_org = useCallback(async (payload) => {
    try {
      const response = await auth_axios.delete(`${authendpoints.authnew.organization}/${payload}`);
    } catch (error) {
      throw new Error(error);
      // console.error(error);
    }
  }, []);
  const set_user_org = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(
        `${authendpoints.authnew.set_user_organization}/${payload.user_id}`,
        payload
      );
    } catch (error) {
      throw new Error(error);
      // console.error(error);
    }
  }, []);

  const get_org_members = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.get_organization_members}/${payload}`
      );
      dispatch({
        type: SET_ORG_MEMBERS,
        payload: response.data,
      });
    } catch (error) {
      // enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
      // console.error(error);
    }
  }, []);
  const add_org_members = useCallback(async (payload) => {
    try {
      await auth_axios.post(`${authendpoints.authnew.add_organization_members}`, payload);
      enqueueSnackbar('Invite link sent to the user email', { variant: 'success' });
      setTimeout(() => navigate(paths.dashboard.user.account), 600);
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      // throw new Error(error);
      // console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch_all_dynamic_forms = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.dynamicform);
      dispatch({
        type: SET_ALL_FORMS,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch bc transactions:', error);
    }
  }, []);

  const fetch_document_schema_forms = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.documentSchema);
      dispatch({
        type: SET_ALL_SCHEMA_FORMS,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch bc transactions:', error);
    }
  }, []);

  const create_dynamic_form = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.documentSchema, payload);
      enqueueSnackbar(messages.jsonCreateSuccess, { variant: 'success' });
      return response.data;
    } catch (e) {
      enqueueSnackbar(Array.isArray(e?.message) ? e?.message[0] : e?.message || e, {
        variant: 'error',
      });
      throw new Error(e);
    }
  }, []);

  const update_dynamic_form = useCallback(async (payload) => {
    try {
      const { document_schema_guid, ...modifyPayload } = payload;
      const response = await auth_axios.patch(
        `${authendpoints.authnew.documentSchema}/${document_schema_guid}`,
        modifyPayload
      );
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const delete_org_data = useCallback(async (payload) => {
    try {
      const response = await auth_axios.delete(authendpoints.authnew.clear_records);

      enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle_blockchain_server = useCallback(async () => {
    try {
      const response = await auth_axios.patch(authendpoints.authnew.toggle_blockchain_server);
      initialize();
    } catch (error) {
      console.error('Error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const create_document_actions = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.documentActions, payload);
      enqueueSnackbar(messages.jsonCreateSuccess, { variant: 'success' });
      return response.data;
    } catch (e) {
      enqueueSnackbar(Array.isArray(e?.message) ? e?.message[0] : e?.message || e, {
        variant: 'error',
      });
      throw new Error(e);
    }
  }, []);

  const update_document_actions = useCallback(async (payload) => {
    try {
      const { document_action_guid, ...modifyPayload } = payload;
      const response = await auth_axios.patch(
        `${authendpoints.authnew.documentActions}/${document_action_guid}`,
        modifyPayload
      );
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      create_form,
      allforms: state.allforms,
      allSchemaforms: state.allSchemaforms,
      fetch_all_forms,
      selected_form: state.selected_form,
      fetch_selected_form,
      post_org,
      get_org,
      orgData: state.orgData,
      get_single_org,
      patch_single_org,
      fetch_all_dynamic_forms,
      delete_org,
      singleOrgData: state.singleOrgData,
      set_user_org,
      get_org_members,
      orgMembersList: state.orgMembersList,
      add_org_members,
      fetch_document_schema_forms,
      create_dynamic_form,
      delete_org_data,
      update_dynamic_form,
      toggle_blockchain_server,
      create_document_actions,
      update_document_actions,
    }),
    [
      state.allforms,
      state.allSchemaforms,
      create_form,
      fetch_all_forms,
      state.selected_form,
      fetch_selected_form,
      post_org,
      get_org,
      fetch_all_dynamic_forms,
      state.orgData,
      get_single_org,
      patch_single_org,
      delete_org,
      state.singleOrgData,
      set_user_org,
      get_org_members,
      state.orgMembersList,
      add_org_members,
      fetch_document_schema_forms,
      create_dynamic_form,
      delete_org_data,
      update_dynamic_form,
      toggle_blockchain_server,
      create_document_actions,
      update_document_actions,
    ]
  );

  return <AdminContext.Provider value={memoizedValue}>{children}</AdminContext.Provider>;
}

export default AdminContextProvider;

AdminContextProvider.propTypes = {
  children: PropTypes.node,
};
