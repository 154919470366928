const EMAIL = /^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z.-]+([.-]?[a-zA-Z]))[.]{1}[a-zA-Z]{2,}$/;
const PASSWORD = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[$@$!%*?&.])(?=.*?[^\w\s]).{8,}$/;
const INT_NUMBER = /^[0-9]{1,2}$/g;
const NAME = /^[A-Za-z]+$/;
const NUMBER = /^[^0-9]$/;
const PHONENUMBER = /^\+(?:[0-9] ?){6,14}[0-9]$/;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  EMAIL,
  PASSWORD,
  INT_NUMBER,
  NAME,
  NUMBER,
  PHONENUMBER,
};
