import { paths } from 'src/routes/paths';

// Access build-time environment variables first, then runtime configuration
const runtimeConfig = window.runtimeConfig || {};

// NOTE - Open this when pushing to main/develop branch
export const SWITCHBOARD_AUTH_HOST_API =
  runtimeConfig.REACT_APP_SWITCHBOARD_AUTH_HOST_API ||
  process.env.REACT_APP_SWITCHBOARD_AUTH_HOST_API;

// NOTE - Open this when developing in localhost
// export const SWITCHBOARD_AUTH_HOST_API =
//   process.env.REACT_APP_SWITCHBOARD_AUTH_HOST_API ||
//   runtimeConfig.REACT_APP_SWITCHBOARD_AUTH_HOST_API;

// NOTE - Open this when pushing to main/develop branch
export const FRONTEND_SERVER_BASE_URL =
  runtimeConfig.REACT_APP_FRONTEND_SERVER_BASE_URL ||
  process.env.REACT_APP_FRONTEND_SERVER_BASE_URL;

// NOTE - Open this when developing in localhost
// export const FRONTEND_SERVER_BASE_URL =
//   process.env.REACT_APP_FRONTEND_SERVER_BASE_URL ||
//   runtimeConfig.REACT_APP_FRONTEND_SERVER_BASE_URL;

export const PATH_FOR_FORM_GENERATOR = paths.dashboard.forms.generate;

export const PATH_AFTER_LOGIN = '/dashboard';

export const SWITCHBOARD_FILE_PATH = '';

export const FRONTEND_VERSION = '1.0.28';

export const MAPBOX_API =
  process.env.REACT_APP_MAPBOX_API || runtimeConfig.REACT_APP_MAPBOX_API || 'default_mapbox_api';

export const ASSETS_API =
  'https://react-switchboard.orangeflower-6613dbff.westus2.azurecontainerapps.io';
// runtimeConfig.REACT_APP_ASSETS_API || process.env.REACT_APP_ASSETS_API || 'default_assets_api';
export const FIREBASE_API = '';

export const SHARED_SWITCHBOARD =
  Boolean(process.env.REACT_APP_IS_SHARED_SWITCHBOARD) ||
  Boolean(runtimeConfig.REACT_APP_IS_SHARED_SWITCHBOARD);

export const INTERCOM_API_BASE =
  runtimeConfig.REACT_APP_INTERCOM_API_BASE || process.env.REACT_APP_INTERCOM_API_BASE;
// export const INTERCOM_API_BASE =
//   process.env.REACT_APP_INTERCOM_API_BASE || runtimeConfig.REACT_APP_INTERCOM_API_BASE;

export const INTERCOM_APP_ID =
  runtimeConfig.REACT_APP_INTERCOM_APP_ID || process.env.REACT_APP_INTERCOM_APP_ID;
// export const INTERCOM_APP_ID =
//   process.env.REACT_APP_INTERCOM_APP_ID || runtimeConfig.REACT_APP_INTERCOM_APP_ID;

export const CONNECT_ENDPOINT =
  runtimeConfig.REACT_APP_STATIC_ORG_URL || process.env.REACT_APP_STATIC_ORG_URL;
// export const CONNECT_ENDPOINT =
//   process.env.REACT_APP_STATIC_ORG_URL || runtimeConfig.REACT_APP_STATIC_ORG_URL;
