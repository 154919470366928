import { useTheme } from '@mui/material/styles';
import { m, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

const SpinnerLoader = ({ loading, loadertext }) => {
  const spinnerVariants = {
    initial: { rotate: 0 },
    animate: { rotate: 360 },
  };

  const textVariants = {
    initial: { opacity: 0 },
    // animate: { opacity: 1 },
    animate: { opacity: 1, transition: { repeat: Infinity, duration: 0.5, repeatType: 'reverse' } },
  };

  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const controls = useAnimation();

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  // Trigger animation when loading state changes
  React.useEffect(() => {
    if (loading) {
      controls.start('animate');
    } else {
      controls.start('initial');
    }
  }, [loading, controls]);

  return (
    <m.div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100vh',
      }}
    >
      <m.div
        style={{
          width: 50,
          height: 50,
          //   border: '5px solid #3498db',
          border: `5px solid ${PRIMARY_MAIN}`,
          borderTop: '5px solid #fff',
          borderRadius: '50%',
        }}
        variants={spinnerVariants}
        initial="initial"
        animate="animate"
        transition={{ duration: 1, ease: 'linear', repeat: Infinity }}
      />

      <m.h2
        style={{
          marginTop: 20,
          fontSize: 16,
          fontWeight: 'bold',
          color: '#333',
        }}
        variants={textVariants}
        initial="initial"
        animate="animate"
        transition={{ duration: 0.5 }}
      >
        {loadertext}
      </m.h2>
    </m.div>
  );
};

SpinnerLoader.propTypes = {
  loading: PropTypes.object,
  loadertext: PropTypes.string,
};

export default SpinnerLoader;
