import PropTypes from 'prop-types';
// @mui
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
import SvgColor from 'src/components/svg-color';
//
import { useContext, useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import Image from 'src/components/image';
import { ContentContext } from 'src/store/contentContext';
import { demoImgURLHandler, orgURLHandler } from 'src/utils/urlHandler';
// import { AccountPopover, NotificationsPopover } from '../_common';
import { Box, Button, Paper, Typography, styled } from '@mui/material';
import { SHARED_SWITCHBOARD } from 'src/config-globals';
import { BLOCKCHAIN_NET_TYPE, UserType } from 'src/config/constants';
import { AdminContext } from 'src/store/adminContext';
import AccountPopover from '../_common/account-popover';
import NotificationsPopover from '../_common/notifications-popover/notifications-popover';
import { HEADER, NAV } from '../config-layout';

// ----------------------------------------------------------------------

const IMAGES = {
  LOGO: '/assets/logos/integra_logo.png',
  MENU_ITEM: '/assets/icons/navbar/ic_menu_item.svg',
};

export default function Header({ onOpenNav }) {
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const settings = useSettingsContext();
  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';
  const lgUp = useResponsive('up', 'lg');
  const offset = useOffSetTop(HEADER.H_DESKTOP);
  const offsetTop = offset && !isNavHorizontal;
  const { system_defaults, user, is_mainnet_network } = useAuthContext();
  const { org_data } = useContext(ContentContext);
  const { toggle_blockchain_server } = useContext(AdminContext);
  const [isMainnet, setIsMainnet] = useState(is_mainnet_network);

  const handleToggle = async () => {
    await toggle_blockchain_server();
  };
  useEffect(() => {
    setIsMainnet(is_mainnet_network);
  }, [is_mainnet_network]);

  const renderCenterLogo = () => {
    if (org_data?.organization_image) {
      return (
        <img
          src={orgURLHandler(org_data?.organization_image)}
          height={50}
          alt={orgURLHandler(org_data?.organization_image)}
        />
      );
    }
    if (user?.organization?.organization_image) {
      return (
        <img
          src={orgURLHandler(user?.organization?.organization_image)}
          height={50}
          alt={orgURLHandler(user?.organization?.organization_image)}
        />
      );
    }
    if (system_defaults) {
      return (
        <img
          src={demoImgURLHandler(system_defaults?.logo)}
          height={50}
          alt={demoImgURLHandler(system_defaults?.logo)}
        />
      );
    }
    return <Image alt="integra_logo.png" src={IMAGES.LOGO} width={90} />;
  };

  // const renderAlert = () => {
  //   enqueueSnackbar(`You're currently on Testnet`, {
  //     persist: true,
  //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
  //     variant: 'warning',
  //   });
  // };

  const renderContent = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%' }}
    >
      {/* Left Section */}
      <Stack direction="row" alignItems="center">
        {!lgUp && (
          <IconButton onClick={onOpenNav}>
            <SvgColor src={IMAGES.MENU_ITEM} />
          </IconButton>
        )}
      </Stack>

      {/* Centered Logo */}
      {!lgUp && renderCenterLogo()}
      {user?.role !== UserType.ADMIN && !isMainnet && (
        <Box
          component={Paper}
          px={1}
          py={0.5}
          variant="outlined"
          bgcolor="#FFE286"
          sx={{
            position: 'fixed',
            top: !lgUp ? '12px' : '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
          }}
        >
          <Typography variant="caption" fontSize="0.85rem">
            Connected to Integra <strong>Testnet</strong>
          </Typography>
        </Box>
      )}

      {/* Right Section */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {user?.role === UserType.ADMIN && SHARED_SWITCHBOARD && (
          <StyledToggleContainer>
            <StyledToggleSubContainer>
              <StyledToggleBox isMainnet={isMainnet} />
              <StyledToggleButton
                disableRipple
                variant="text"
                isMainnet={isMainnet}
                onClick={handleToggle}
              >
                {BLOCKCHAIN_NET_TYPE.MAIN_NET}
              </StyledToggleButton>
              <StyledToggleButton
                disableRipple
                variant="text"
                isMainnet={!isMainnet}
                onClick={handleToggle}
              >
                {BLOCKCHAIN_NET_TYPE.TEST_NET}
              </StyledToggleButton>
            </StyledToggleSubContainer>
          </StyledToggleContainer>
        )}

        {/* {user && user?.role !== UserType.ORG && !isMainnet && renderAlert()} */}

        <NotificationsPopover />
        <AccountPopover />
      </Stack>
    </Stack>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
Header.propTypes = {
  onOpenNav: PropTypes.func,
};

const StyledToggleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
}));
const StyledToggleSubContainer = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  position: 'relative',
  background: theme.palette.grey[300],
}));

const StyledToggleBox = styled(Box)(({ theme, isMainnet }) => ({
  width: '80px',
  height: '35px',
  borderRadius: '20px',
  background: theme.palette.primary.main,
  position: 'absolute',
  transition: 'all 0.5s ease',
  transform: `translateX(${isMainnet ? 0 : '80px'})`,
}));

const StyledToggleButton = styled(Button)(({ theme, isMainnet }) => ({
  borderRadius: '20px',
  width: '80px',
  height: '35px',
  fontWeight: 'bold',
  transition: 'all 0.2s 0.1s ease',
  color: isMainnet ? '#ffffff' : 'text.primary',
  '&:hover': {
    opacity: 0.8,
  },
}));
