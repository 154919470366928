import { m } from 'framer-motion';
import { useCallback, useContext, useEffect, useState } from 'react';
// @mui
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// import { _notifications } from 'src/_mock';
// components
import { varHover } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
//
import { useAuthContext } from 'src/auth/hooks';
import { ContentContext } from 'src/store/contentContext';
import { useSocket } from 'src/store/socketContext';
import NotificationItem from './notification-item';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const { socket } = useSocket();
  const { user } = useAuthContext();
  const smUp = useResponsive('up', 'sm');

  const [currentTab, setCurrentTab] = useState('all');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const {
    get_notifications,
    notifications: allNotifications,
    delete_notifications,
    read_notifications,
  } = useContext(ContentContext);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    get_notifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setNotifications(allNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotifications]);

  const HandleDeleteNotifications = async () => {
    try {
      await delete_notifications();
    } catch (error) {
      console.error('Error deleting organization data:', error);
      // Handle error case (e.g., show error message)
    }
  };

  useEffect(() => {
    if (socket && user?.id) {
      socket.emit('users', { user_id: user?.id }, (data) => {
        console.log('emittted Data', data);
      });
    }
    if (socket) {
      socket.on('receiveNotification', (new_notification) => {
        console.log('new_notification Data', new_notification);
        setNotifications((prevNotifications) => [new_notification, ...prevNotifications]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, user?.id]);

  const totalUnRead = notifications.filter((item) => item.is_read === false).length;

  const TABS = [
    {
      value: 'all',
      label: 'All',
      count: notifications?.length,
    },
    {
      value: 'unread',
      label: 'Unread',
      count: totalUnRead,
    },
    {
      value: 'archived',
      label: 'Read',
      count: notifications.length > 0 ? notifications.length - totalUnRead : 0,
    },
  ];

  const handleMarkAllAsRead = async () => {
    const notificationIds = notifications.map((notification) => notification.id);
    const payload = {
      notification_ids: notificationIds,
    };
    await read_notifications(payload);

    // setNotifications(
    //   notifications.map((notification) => ({
    //     ...notification,
    //     is_read: true,
    //   }))
    // );
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {/* {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )} */}

      {/* {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )} */}
      <IconButton onClick={drawer.onFalse}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onCloseDrawer={drawer.onFalse}
          />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
          {!!totalUnRead && (
            <Tooltip title="Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        <Divider />

        {renderList}

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            size="large"
            onClick={HandleDeleteNotifications}
            variant="contained"
            color="primary"
          >
            Clear All Notifications
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
