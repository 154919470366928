import { m } from 'framer-motion';
// @mui
import Typography from '@mui/material/Typography';
// layouts
import MobileLayout from 'src/layouts/mobile';
// routes
// components
import { Stack } from '@mui/material';
import { MotionContainer, varBounce } from 'src/components/animate';
// assets

// ----------------------------------------------------------------------

export default function MobileScreenView() {
  return (
    <MobileLayout>
      <Stack sx={{ justifyContent: 'center', height: '100%' }}>
        <MotionContainer>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              {/* You are on a mobile device */}
              Mobile version coming soon.
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              Please visit this page on a desktop browser.
            </Typography>
          </m.div>

          {/* <m.div variants={varBounce().in}>
          <PageNotFoundIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div> */}

          {/* <Button component={RouterLink} href="/" size="large" variant="contained">
          Go to Home
        </Button> */}
        </MotionContainer>
      </Stack>
    </MobileLayout>
  );
}
