// ? import all hardcoded messages here

const transferDocSuccessMsg = 'Tokenized document verified successfully.';
const transferDocErrorMsg = 'Uploaded document is not a verifed tokenized document.';
const verifyDocSuccessMsg = 'Key imported successfully. Ownership of document has been verified.';
const verifyDocErrorMsg =
  'Key is not owner of document. Please check your uploaded document and the verifying document and retry again.';
const docUploadSuccessMsg =
  'Document registered successfully. Please check the recepient email for redirection link';
const docTransferAPISuccessMsg =
  'Document transferred successfully. Please check the recepient email for redirection link';
const docUploadFailureMsg = 'Failed to create tokenized document. Please try again';
const docTransferAPIErrorMsg = 'Failed to transfer document';
const postMyAppSuccess = 'Application installed successfully';
const delMyAppSuccess = 'Application uninstalled successfully';
const AddNewAppSucess = 'New application add successfully';
const UpdateAppSucess = 'Application updated successfully';
const RemoveAppSucess = 'App removed from App Store';
const docUploadNoRegSuccessMsg = 'Document registered successfully';
const tryAgainMsg = 'An unexpected error occurred. please try again';
const documentDownloaded = 'Your document has been downloaded';
const docUploadSuccessMsgv2 = `Document registered successfully. You'll be redirected to DocuSign platform shortly`;
const docTransferAPISuccessMsgv2 = 'Document transferred successfully';
const tooltipText = {
  VC: 'Verify your counterparties with secure digital credentials',
  DOC_ACTIONS: 'Enable Automation and enterprise integration.',
  DEFAULT: 'Click on green box to view details.',
};
const offlineText = 'Network Error! Please check your internet connection.';
const jsonCreateSuccess = 'JSON Schema created successfully';
const inviteMSG =
  'Member invited successfully. An email will be forwarded to the linked email shortly';
export default {
  transferDocSuccessMsg,
  transferDocErrorMsg,
  verifyDocSuccessMsg,
  verifyDocErrorMsg,
  docUploadSuccessMsg,
  docTransferAPISuccessMsg,
  docTransferAPIErrorMsg,
  docUploadFailureMsg,
  postMyAppSuccess,
  delMyAppSuccess,
  AddNewAppSucess,
  UpdateAppSucess,
  RemoveAppSucess,
  docUploadNoRegSuccessMsg,
  tryAgainMsg,
  docUploadSuccessMsgv2,
  docTransferAPISuccessMsgv2,
  tooltipText,
  offlineText,
  jsonCreateSuccess,
  inviteMSG,
};
