import SimpleLayout from "src/layouts/simple/layout";

const { Suspense, lazy } = require("react");
const { Outlet } = require("react-router");
const { GuestGuard } = require("src/auth/guard");
const { SplashScreen } = require("src/components/loading-screen");

const AdminCreateFormsPage = lazy(() => import('src/pages/admin/forms/createForms'));
const AdminGeneratorPage = lazy(() => import('src/pages/admin/forms/generator'));

const admin = {
    path: 'forms',
    element: (
        <GuestGuard>
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        </GuestGuard>
    ),
    children: [
        {
            path: 'create_forms',
            element: (
                <SimpleLayout>
                    <AdminCreateFormsPage />
                </SimpleLayout>
            ),
        },
        {
            path: 'generator',
            element: (
                <SimpleLayout>
                    <AdminGeneratorPage />
                </SimpleLayout>
            ),
        }
    ],
};

export const adminRoutes = [
    {
        path: 'admin',
        children: [admin],
    },
];