import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  hideCancel,
  cancelText,
  ...other
}) {
  return (
    <Dialog
      fullWidth
      maxWidth={other.maxWidth ? other.maxWidth : 'xs'}
      open={open}
      onClose={onClose}
      {...other}
    >
      <DialogTitle sx={{ pb: 2, textAlign: 'center' }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions>
        {action}

        {!hideCancel && (
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {cancelText || 'Close'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  hideCancel: PropTypes.bool,
  cancelText: PropTypes.string,
};
