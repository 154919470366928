import {
  FRONTEND_SERVER_BASE_URL,
  // SWITCHBOARD_AUTH_HOST_API,
  SWITCHBOARD_FILE_PATH,
} from 'src/config-globals';

export const appImgURLHandler = (fileName) => {
  const url = `${FRONTEND_SERVER_BASE_URL}${SWITCHBOARD_FILE_PATH}/application-files/${fileName}`;
  // const url = `${SWITCHBOARD_AUTH_HOST_API}${SWITCHBOARD_FILE_PATH}/application-files/${fileName}`;
  return url;
};

export const docfileHandler = (fileName) => {
  // Remove /root from the beginning of the fileName
  const sanitizedFileName = fileName.replace(/^\/root\//, '');

  const url = `${FRONTEND_SERVER_BASE_URL}/${sanitizedFileName}`;
  // const url = `${SWITCHBOARD_AUTH_HOST_API}/${sanitizedFileName}`;
  return url;
};

export const orgURLHandler = (fileName) => {
  const url = `${FRONTEND_SERVER_BASE_URL}${SWITCHBOARD_FILE_PATH}/application-files/organization-files/${fileName}`;
  // const url = `${SWITCHBOARD_AUTH_HOST_API}${SWITCHBOARD_FILE_PATH}/organization-files/${fileName}`;
  return url;
};

export const demoImgURLHandler = (fileName) => {
  if (fileName) {
    const url = `${FRONTEND_SERVER_BASE_URL}${SWITCHBOARD_FILE_PATH}/application-files/demo/${fileName}`;
    // const url = `${SWITCHBOARD_AUTH_HOST_API}${SWITCHBOARD_FILE_PATH}/application-files/demo/${fileName}`;
    return url;
  }
  return null;
};
export const chatURLHandler = (fileName) => {
  console.log(fileName, 'fileName$$$$$$$$$$$$$$$$$');
  if (fileName) {
    const modifiedUrl = fileName.replace('root/', '');
    const url = `${FRONTEND_SERVER_BASE_URL}${SWITCHBOARD_FILE_PATH}${modifiedUrl}`;
    // const url = `${SWITCHBOARD_AUTH_HOST_API}${SWITCHBOARD_FILE_PATH}/chat-documents/${fileName}`;
    return url;
  }
  return null;
};
