const docuSign_my_app_id = 20; // ! Remove this if no use
const docuSign_app_id = 95; // * Primary ID for DocuSign in application DB
const docuSign_app_name = ['E-Sign and Tokenize a Contract', 'DocuSign Connector', 'Intel']; // * Primary ID for DocuSign in application DB
const docuSign_auth_link = 'https://account-d.docusign.com/oauth/auth';
const docuSign_userInfo_URI = 'https://account-d.docusign.com/oauth/userinfo';
const docuSign_eSignature_URI = 'restapi/v2.1/accounts/';
const docuSign_scope = 'cors signature';
const intergraServerURL = `${window.location.origin}/verifiedbyintegra`;
// const docuSign_signing_ceremony_url = '';
const docuSign_default_scope = 'signature';
const docuSign_new_integration_key = '07842c56-39a8-42ba-8a6e-a79e36ad175c';
const docuSign_auth_service = 'https://account-d.docusign.com/oauth/token';
const docuSign_new_secret_key = 'b2aa02c9-ffd2-4f7d-a20f-031288d5a09b';
const docuSign_eSignature_REST_API = 'https://demo.docusign.net/';

const DocumentType = {
  CREATE: 1,
  TRANSFER: 2,
};

export default {
  docuSign_my_app_id,
  docuSign_app_id,
  docuSign_app_name,
  docuSign_auth_link,
  docuSign_userInfo_URI,
  docuSign_eSignature_URI,
  docuSign_scope,
  // docuSign_signing_ceremony_url,
  DocumentType,
  intergraServerURL,
  docuSign_default_scope,
  docuSign_new_integration_key,
  docuSign_auth_service,
  docuSign_new_secret_key,
  docuSign_eSignature_REST_API,
};
