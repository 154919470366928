import { Container, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import MobileScreenView from 'src/sections/error/mobile-screen-view';
import { HeaderSimple as Header } from '../_common';

// ----------------------------------------------------------------------

export default function SimpleLayout({ children, expand, frontPage }) {
  useEffect(() => {
    window.Intercom('update');
  }, [children]);

  const isMobile = useIsMobile();

  return isMobile ? (
    <MobileScreenView />
  ) : (
    <>
      <Header frontPage={frontPage} />

      {/* {children} */}

      <Container component="main" maxWidth={frontPage && 'xl'}>
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            // maxWidth: 400,
            minHeight: '100vh',
            // textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}

SimpleLayout.propTypes = {
  children: PropTypes.node,
  expand: PropTypes.bool,
  frontPage: PropTypes.bool,
};
