import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MuiPhone from '../phone-number';

export default function RHFPhoneNumber({ name, label, helperText, sx, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiPhone
          label={label}
          value={field.value}
          onChange={field.onChange}
          error={!!error}
          helperText={error ? error.message : helperText}
          sx={sx}
          {...other}
        />
      )}
    />
  );
}

RHFPhoneNumber.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  sx: PropTypes.object,
};
