/* eslint-disable jsx-a11y/alt-text */
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// components
import { useAuthContext } from 'src/auth/hooks';
// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { user } = useAuthContext();

  // const { t } = useLocales();

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box
          sx={{
            display: 'flex', // Make this a flex container
            justifyContent: 'center', // Center children horizontally
            position: 'relative',
          }}
        >
          {/* ... your other components ... */}
          {/* <Logo sx={{ mt: 3, mb: 1 }} /> */}
          {/* TODO: Add organizational logo here */}
          {/* <img src={user?.companyPhotoURL} width="90" height="90" />{' '} */}
          {/* <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            xFree
          </Label> */}
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.email}
          </Typography>
        </Stack>

        {/* <Button variant="contained" href={paths.minimalUI} target="_blank" rel="noopener">
          {t('upgrade_to_pro')}
        </Button> */}
      </Stack>
    </Stack>
  );
}
