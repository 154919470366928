import { useEffect, useState } from 'react';

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice = /android|iPad|iPhone|iPod/i.test(userAgent.toLowerCase());
    setIsMobile(isMobileDevice);
  }, []);

  return isMobile;
}
