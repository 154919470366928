/* This code snippet is setting up a SocketProvider component in React that creates a socket connection
using socket.io-client library. Here's a breakdown of what the code is doing: */
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import io from 'socket.io-client';
import { SWITCHBOARD_AUTH_HOST_API } from 'src/config-globals';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  // REVIEW - Socket Provider
  useEffect(() => {
    const newSocket = io(SWITCHBOARD_AUTH_HOST_API, {
      transports: ['websocket'],
    });

    newSocket.on('connect', () => {
      setIsConnected(true);
      console.log('Socket connected');
    });

    newSocket.on('disconnect', () => {
      setIsConnected(false);
      console.log('Socket disconnected');
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const disconnectSocket = useMemo(() => {
    if (socket) {
      return () => socket.disconnect();
    }
    return () => {};
  }, [socket]);

  const contextValue = useMemo(() => ({ socket, disconnectSocket }), [socket, disconnectSocket]);

  return <SocketContext.Provider value={contextValue}>{children}</SocketContext.Provider>;
};

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
