import PropTypes from 'prop-types';
// @mui
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
//
import { useEffect } from 'react';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import MobileScreenView from 'src/sections/error/mobile-screen-view';
import { HeaderSimple as Header } from '../_common';

// ----------------------------------------------------------------------

export default function CompactLayout({ children }) {
  useEffect(() => {
    window.Intercom('update');
  }, [children]);

  const isMobile = useIsMobile();

  return isMobile ? (
    <MobileScreenView />
  ) : (
    <>
      <Header />

      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: 400,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}

CompactLayout.propTypes = {
  children: PropTypes.node,
};
