import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';
import MainLayout from 'src/layouts/main';
// components
import { AuthGuard } from 'src/auth/guard';
import { SplashScreen } from 'src/components/loading-screen';
import SimpleLayout from 'src/layouts/simple/layout';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const VerifiedByIntegraPage = lazy(() => import('src/pages/verifiedbyintegra'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const PricingPage = lazy(() => import('src/pages/pricing'));
const PaymentPage = lazy(() => import('src/pages/payment'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
// PRODUCT
const ProductListPage = lazy(() => import('src/pages/product/list'));
const ProductDetailsPage = lazy(() => import('src/pages/product/details'));
const ProductCheckoutPage = lazy(() => import('src/pages/product/checkout'));
// BLOG
const PostListPage = lazy(() => import('src/pages/post/list'));
const PostDetailsPage = lazy(() => import('src/pages/post/details'));

// ----------------------------------------------------------------------
const SigningKeyViewPage = lazy(() => import('src/pages/dashboard/signingkey'));
const VerifyDocumentPage = lazy(() => import('src/pages/verify'));
const VerifyWellsPage = lazy(() => import('src/pages/verify_wells'));
const VerifyIdentityPage = lazy(() => import('src/pages/verify_identity'));
const SigningCeremonyPage = lazy(() => import('src/pages/dashboard/signingceremony'));
const IntelNewContractPage = lazy(() => import('src/pages/intel/new-contract'));

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'about-us', element: <AboutPage /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'faqs', element: <FaqsPage /> },
      // { path: 'verifiedbyintegra', element: <VerifiedByIntegraPage /> },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'checkout', element: <ProductCheckoutPage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <PostListPage />, index: true },
          { path: 'list', element: <PostListPage /> },
          { path: ':title', element: <PostDetailsPage /> },
        ],
      },
    ],
  },
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    ),
    children: [
      { path: 'pricing', element: <PricingPage /> },
      { path: 'payment', element: <PaymentPage /> },
      { path: 'verify', element: <VerifyDocumentPage /> },
      { path: 'verify_wells', element: <VerifyWellsPage /> },
      { path: 'verify_identity', element: <VerifyIdentityPage /> },
      { path: 'signing_key', element: <SigningKeyViewPage /> },
      { path: 'signing_ceremony', element: <SigningCeremonyPage /> },
      { path: 'transfer', element: <SigningKeyViewPage /> },
      { path: 'verifiedbyintegra', element: <VerifiedByIntegraPage /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'coming-soon', element: <ComingSoonPage /> },
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
  {
    element: (
      <AuthGuard>
        <SimpleLayout expand>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </SimpleLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'intel',
        children: [
          { element: <IntelNewContractPage />, index: true },
          { path: 'new-contract', element: <IntelNewContractPage /> },
        ],
      },
    ],
  },
];
