import PropTypes from 'prop-types';
// @mui
//
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { HeaderSimple as Header } from '../_common';

// ----------------------------------------------------------------------

export default function MobileLayout({ children }) {
  useEffect(() => {
    window.Intercom('update');
  }, [children]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header hideIcon />

      <Box
        component="main"
        sx={{
          py: 12,
          m: 'auto',
          maxWidth: 400,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

MobileLayout.propTypes = {
  children: PropTypes.node,
};
