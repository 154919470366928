import { SWITCHBOARD_AUTH_HOST_API } from 'src/config-globals';
import { authendpoints } from 'src/utils/authAxios';
import { _mock } from './_mock';
import { _tags } from './assets';

// ----------------------------------------------------------------------

const GB = 1000000000 * 24;

const FOLDERS = [];
// let formattedDocuments = [];

export const DOCUMENT_TYPES = [
  'folder',
  'txt',
  'zip',
  'audio',
  'image',
  'video',
  'word',
  'excel',
  'powerpoint',
  'pdf',
  'photoshop',
  'illustrator',
];

export const FILE_TYPE_OPTIONS = [
  'folder',
  'txt',
  'zip',
  'audio',
  'image',
  'video',
  'word',
  'excel',
  'powerpoint',
  'pdf',
  'photoshop',
  'illustrator',
];

const SHARED_PERSONS = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  email: _mock.email(index),
  avatarUrl: _mock.image.avatar(index),
  permission: index % 2 ? 'view' : 'edit',
}));

const shared = (index) =>
  (index === 0 && SHARED_PERSONS.slice(0, 5)) ||
  (index === 1 && SHARED_PERSONS.slice(5, 9)) ||
  (index === 2 && SHARED_PERSONS.slice(9, 11)) ||
  (index === 3 && SHARED_PERSONS.slice(11, 12)) ||
  [];

export const _folders = FOLDERS.map((name, index) => ({
  id: `${_mock.id(index)}_folder`,
  name,
  type: 'folder',
  url: index,
  shared: shared(index),
  tags: _tags.slice(0, 5),
  size: GB / ((index + 1) * 10),
  totalFiles: (index + 1) * 100,
  createdAt: _mock.time(index),
  modifiedAt: _mock.time(index),
  isFavorited: _mock.boolean(index + 1),
}));

async function fetchData() {
  return new Promise((resolve, reject) => {
    // Replace '<token goes here>' with your actual authorization token
    const token =
      'eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiemlwIjoiREVGIn0.-qUb03StwzjYCYuslC4xtNojnGDuf77ZmFaeWE4-P9aOvPuHFkwsiPga6QJp0xtUGczYLN0fZlmrsuGWdQaQn5Nwi3FECiAt.v2O31kmT0zNM740YsvW0NQ.WFKFvUNnt5jNl5nhmsFYJd8oOY5Z3eNEs7T1_NRIKnljpFxYhSpq8TOer4hc38FhKMQnXmKlNOtnRNRxrv5LU8TahdfdjUg9D61fy1sQ7G8tvA1geaEI6jBfa8MX3JTD_hsHPmnvVEJxjXHtA6kBKQ.kIqMOsWUL_ZUPpmAj6d6WkXF8Zbs9Qo6RyJJBg0YnsA';
    // const apiUrl = 'https://xsqy-f6go-huo7.n7c.xano.io/api:lkmcgxf_/switchboard/get_documents';
    const apiUrl = `${SWITCHBOARD_AUTH_HOST_API}${authendpoints.authnew.get_documents}`;

    // Create the fetch request
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Filter the dataset to get only documents
        const documents = data.filter((file) => {
          const fileType = file.document_name.split('.').pop().toLowerCase();
          return DOCUMENT_TYPES.includes(fileType);
        });

        // Format the documents array to match the original structure
        const formattedDocuments = documents.map((doc, index) => ({
          id: `${_mock.id(index)}_file`,
          name: doc.document_name,
          url: 'http://localhost:3030/assets/images/cover/cover_2.jpg',
          type: doc.document_type,
          shared: shared(index),
          tags: _tags.slice(0, 5),
          size: 48000000,
          totalFiles: '1',
          createdAt: doc.created_at,
          modifiedAt: doc.created_at,
          isFavorited: false,
          metadata: doc.metadata,
          integraid: doc.integra_id,
        }));

        // Resolve the promise with the formattedDocuments
        resolve(formattedDocuments);
      })
      .catch((error) => {
        // Reject the promise with the error
        reject(error);
      });
  });
}

export const _files = []; // await fetchData();

export const _allFiles = [..._folders, ..._files];
