import { formatDistanceToNowStrict, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';

export function useFormattedTime(initialCreatedAt) {
  const [timeStamp, setTimeStamp] = useState(() => getFormattedTime(initialCreatedAt));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeStamp(getFormattedTime(initialCreatedAt));
    }, 1000);
    return () => clearInterval(interval);
  }, [initialCreatedAt]);

  function getFormattedTime(createdAt) {
    const createdAtDate = new Date(createdAt);
    const now = new Date();

    if (isBefore(now, createdAtDate)) {
      return ''; // Invalid date
    }

    const difference = Math.abs(now - createdAtDate) / 1000;

    if (difference < 60) {
      return 'Less than a minute';
    }

    return formatDistanceToNowStrict(createdAtDate, {
      addSuffix: true,
    });
  }

  return timeStamp;
}
