/* eslint-disable react/jsx-no-useless-fragment */
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
import { useSettingsContext } from 'src/components/settings';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { ContentContext } from 'src/store/contentContext';
// routes
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useParams } from 'src/routes/hooks';
// _mock
// components
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FormProvider, { RHFAutocomplete, RHFEditor, RHFTextField } from 'src/components/hook-form';
import { SplashScreen } from 'src/components/loading-screen';
import { FRONTEND_SERVER_BASE_URL } from 'src/config-globals';
import { DOCUSIGN_KEY } from 'src/config/constants';
//
// import PostDetailsPreview from './post-details-preview';

// ----------------------------------------------------------------------
export default function JobEdit({ currentPost }) {
  const {
    // get_all_app_config,
    // appConfig,
    get_single_app_config,
    singleAppConfig,
    add_app_config,
    update_app_config,
    // update_app_config_auth_info,
    remove_app_config,
    get_app_tags,
    appTags,
    get_my_app_detail,
    myAppDetail,
  } = useContext(ContentContext);
  const params = useParams();
  // const extractedDocuSignSessionData = JSON.parse(localStorage.getItem('docusignData')) ?? null; // * Data extracted from the localStorage (for Logged in users)
  // const docusign_auth = extractedDocuSignSessionData?.docusign_auth ?? null;
  const { id } = params;
  const isUpdateConfig = myAppDetail.has_config;
  const settings = useSettingsContext();
  const isLoading = useBoolean(true);
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  const [extractedDocuSignSessionData, setExtractedDocuSignSessionData] = useState(null);
  useEffect(() => {
    const extractedData = JSON.parse(localStorage.getItem(DOCUSIGN_KEY));
    if (extractedData) {
      setExtractedDocuSignSessionData(extractedData);
    }
  }, []);

  const AddConfigSchema = Yup.object().shape({
    client_id: Yup.string().required('Client ID is required'),
    app_user_id: Yup.string().required('User ID is required'),
    email_text: Yup.string(),
    // access_token: Yup.string().required('Access Token / Key is required'),
    access_token: Yup.string().required('Access Token / Key is required'),
    sendgrid_template_id: Yup.string(),
    // app_tags: Yup.array().required('Application tag is required'),
    return_uri: Yup.string(),
    // .matches(url_regex, 'URL is not valid')
  });

  const methods = useForm({
    resolver: yupResolver(AddConfigSchema),
    // defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { isSubmitting, isValid, errors, defaultValues },
  } = methods;
  const formValues = watch();

  useEffect(() => {
    if (!isUpdateConfig) {
      reset({
        client_id: '',
        app_user_id: '',
        return_uri: '',
        access_token: '',
        sendgrid_template_id: '',
        email_text: '',
        app_tags: [],
      });
    } else {
      reset({
        client_id: singleAppConfig.client_id ?? '',
        app_user_id: singleAppConfig.app_user_id ?? '',
        return_uri: singleAppConfig.return_uri ?? '',
        sendgrid_template_id: singleAppConfig.sendgrid_template_id ?? '',
        email_text: singleAppConfig.email_text ?? '',
        access_token: singleAppConfig.access_token ?? '',
        token_expiration: singleAppConfig.token_expiration ?? '',
        app_tags: singleAppConfig.app_tags ?? [],
      });
    }
  }, [singleAppConfig, isUpdateConfig, reset]);

  const handleUpdateAddConfigRequest = async (data) => {
    try {
      const payload = {
        myapplications_id: Number(myAppDetail?.myapplication?.id),
        client_id: data.client_id,
        app_user_id: data.app_user_id,
        return_uri: data.return_uri,
        // access_token: extractedDocuSignSessionData?.access_token ?? null, // ? pass the actual docusign access token here
        // token_expiration: extractedDocuSignSessionData?.expiry_time ?? null, // ? pass the actual token expiration here
        access_token: data?.access_token ?? null, // ? pass the actual docusign access token here
        // ? pass the actual token expiration here
        sendgrid_template_id: data.sendgrid_template_id,
        email_text: data.email_text,
        app_tags: data.app_tags,
        // * add more payload according to key requirements
      };

      await update_app_config(payload, myAppDetail?.myapplicationconfig?.id);
      get_my_app_detail(id);
      get_app_tags();
      setOpen(true);
    } catch (err) {
      console.log(err);
    }
  };
  const handleAddAppConfigRequest = async (data) => {
    try {
      const payload = {
        myapplications_id: Number(myAppDetail?.myapplication?.id),
        client_id: data.client_id,
        app_user_id: data.app_user_id, // ! remove as not required at the time of adding
        return_uri: data.return_uri,
        access_token: data.access_token, // ! remove as not required at the time of adding
        // token_expiration: '2023-12-31', // ! remove as not required at the time of adding
        sendgrid_template_id: data.sendgrid_template_id,
        email_text: data.email_text,
        app_tags: data.app_tags,
        // * add more payload according to key requirements
      };
      await add_app_config(payload);
      get_my_app_detail(id);
      get_app_tags();
      setOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveAppConfigRequest = async (app_id) => {
    try {
      await remove_app_config(app_id);
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (isUpdateConfig) {
      handleUpdateAddConfigRequest(data);
    } else {
      handleAddAppConfigRequest(data);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('coverUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  useEffect(() => {
    get_my_app_detail(id);
    get_app_tags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isUpdateConfig) {
      isLoading.onFalse();
      return;
    }
    const init = async () => {
      try {
        await get_single_app_config(myAppDetail?.myapplicationconfig?.id);
        isLoading.onFalse();
      } catch (error) {
        isLoading.onFalse();
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myAppDetail]);

  const handleRemoveFile = useCallback(() => {
    setValue('coverUrl', null);
  }, [setValue]);

  const [open, setOpen] = useState(false);

  const renderDetails = () => (
    <>
      {mdUp && (
        <Grid md={5}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            <h3>Docusign Developer Account Configuration</h3>

            <ol>
              <li>
                Sign up for a developer account at{' '}
                <a href="https://go.docusign.com/o/sandbox/">https://go.docusign.com/o/sandbox/</a>
              </li>
              <li>Once logged in, click on the Settings tab in the top navigation bar.</li>
              <li>On the left-hand navigation bar, click on Integrations &gt; Apps and Keys.</li>
              <li>Click on the blue Add App and Integration Key button.</li>
              <li>Enter “Integra Integra Connect” for the App Name.</li>
              <li>
                On the General Info page, make sure the following are selected:
                <ul>
                  <li>
                    For the question “Is your application able to securely store a client secret?”
                    question, select No. The Authentication Method for your App should now say
                    “Implicit Grant.”
                  </li>
                  <li>
                    Under Additional Settings, click the “Add URI” button and enter your Integra
                    Connect redirect URIs, for the online Integra Connect, the default is
                    <a href={`${FRONTEND_SERVER_BASE_URL}/dashboard/apps/docusign/redirect`}>
                      {' '}
                      {`${FRONTEND_SERVER_BASE_URL}/dashboard/apps/docusign/redirect`}
                    </a>
                  </li>
                  <li>
                    Under Additional Settings, click on the “Add Origin URL” and enter your Integra
                    Connect URL or for the online Integra Connect, the default is
                    <a href={FRONTEND_SERVER_BASE_URL}> {FRONTEND_SERVER_BASE_URL}</a>.
                  </li>
                  <li>
                    For Allowed HTTP Methods, select all options (GET, POST, PUT, DELETE, and HEAD).
                  </li>
                </ul>
              </li>
              <li>Click the Save button.</li>
              <li>
                On the Apps and Keys, take note of the Integration Key and paste it into your
                Docusign Connection Settings page and paste it in for the Client Id.
              </li>
            </ol>

            <p>
              When you click on the “Docusign Login” from the My Apps page, you will see a prompt
              like below:
            </p>
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={7}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            {/* <RHFTextField name="description" label="Description" multiline rows={3} /> */}

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Client ID / Integration Key</Typography>
              <RHFTextField name="client_id" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Platform User ID</Typography>
              <RHFTextField name="app_user_id" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Redirect URL</Typography>
              <RHFTextField name="return_uri" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Access Token / RSA PRIVATE KEY</Typography>
              <RHFTextField name="access_token" multiline rows={4} />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">SendGrid Template ID</Typography>
              <RHFTextField name="sendgrid_template_id" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Email Text</Typography>
              <RHFEditor simple name="email_text" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Application Tags</Typography>
              <RHFAutocomplete
                multiple
                disableCloseOnSelect
                name="app_tags"
                id="app_tags"
                // filterSelectedOptions
                placeholder="Tags"
                // defaultValue={defaultValues?.app_tags}
                // isOptionEqualToValue={(option, value) => option === value}
                options={appTags.map((option) => option.tag_name)}
                // defaultValue={appTags.find((tags) => tags.tag_name === getValues().app_tags)}
                // getOptionSelected={appTags.find((tags) => tags.tag_name === getValues().app_tags)}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                renderTags={(selected, getTagProps) => {
                  console.log('Selected: ------------ ', selected);
                  return selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option}
                      label={option}
                      size="small"
                      color="info"
                      variant="soft"
                    />
                  ));
                }}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={5} />}
      <Grid xs={12} md={7} sx={{ display: 'flex', alignItems: 'center' }}>
        {myAppDetail && myAppDetail?.myapplicationconfig && (
          <Button
            color="inherit"
            variant="outlined"
            size="large"
            onClick={() => handleRemoveAppConfigRequest(myAppDetail?.myapplicationconfig?.id)}
          >
            Delete Configuration
          </Button>
        )}

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          // onClick={onSubmit}
          sx={{ ml: 2 }}
        >
          {isUpdateConfig ? 'Update Configuration' : 'Add Configuration'}
        </LoadingButton>
      </Grid>
    </>
  );

  return isLoading.value ? (
    <SplashScreen />
  ) : (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <CustomBreadcrumbs
            heading={myAppDetail?.application?.app_name}
            links={[
              { name: 'My Apps', href: '/dashboard/myapps' },
              {
                name: 'App Config',
                href: `/dashboard/myapps/${myAppDetail?.myapplication?.app_guid}`,
              },
              { name: isUpdateConfig ? 'Edit Configuration' : 'Add Configuration' },
            ]}
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
        </Stack>

        <FormProvider methods={methods} onSubmit={onSubmit}>
          {/* <FormProvider {...methods} onSubmit={onSubmit}> */}
          <Grid container spacing={3}>
            {renderDetails()}

            {/* {renderProperties} */}

            {renderActions}
          </Grid>
        </FormProvider>
      </Container>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Configuration Status</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Configuration settings have been {isUpdateConfig ? 'updated' : 'added'} successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              // navigate(-1);
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

JobEdit.propTypes = {
  currentPost: PropTypes.object,
};
