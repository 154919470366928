import axios from 'axios';
// config
import { SWITCHBOARD_AUTH_HOST_API } from 'src/config-globals';

// ----------------------------------------------------------------------

const auth_axios = axios.create({ baseURL: SWITCHBOARD_AUTH_HOST_API });

auth_axios.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default auth_axios;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await auth_axios.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const authendpoints = {
  authnew: {
    signup: '/auth/register',
    login: '/auth/login',
    userinfo: '/auth/me',
    content: '/switchboard/get_documents',
    files: '/switchboard/get_documents',
    bctrans: 'transactions/findAll',
    channel: '/switchboard/get_channels',
    channel_message: '/switchboard/get_channel_messages',
    get_documents: '/document/all/0',
    document: '/document',
    document_no_register: '/docusign/document_no_register',
    getapps: '/applications',
    myapps: '/myapplications',
    add_myapp: '/myapplications/add',
    docusign_document: '/docusign',
    add_docusign_document: '/docusign/docusign_document',
    update_docusign_document: '/docusign/update_document',
    transfer_docusign_document: '/document_docusign_transfer',
    app_config_record: '/myapplicationsconfig',
    remove_myapp: '/myapplications/delete',
    getmyapp: '/myapplicationsconfig/by-app-guid',
    app_tags: '/application-tags',
    documentActions: '/document-actions',
    myapp_by_guid: '/get_my_application_id_by_guid',
    get_my_application_search_by_tag: '/get_my_application/search_by',
    organization: '/organization',
    set_user_organization: '/organization/set_user_org_by_id',
    get_organization_members: '/organization/get_members',
    add_organization_members: '/organization/member/add_members',
    edit_organization_members: '/organization/member/edit_member',
    user_profile: '/user',
    docusign_token: '/docusign/fetchAccessToken',
    docusign_auth: '/docusign/accounts',
    docusign_envelope: '/docusign/accounts_envelopes',
    docusign_envelope_recipient: '/docusign/envelope_view',
    verify_digest_signature: `/document-registry/verifyDigestSignature`,
    identity_exist_by_integraId: `/document-registry/identityexists`,
    get_explorer_url: `/transactions/identityexists/`,
    identity_exist_by_document_hash: `/transactions/hash-exists/`,
    defaultapplication: `/defaultapplication`,
    defaultsettings: `/defaultsettings/`,
    resetdefaultsettings: `/defaultsettings/reset`,
    system_defaults: `/defaultsettings/default`,
    default_apps: `application-tags/myapplication`,
    update_default_apps: `defaultapplication/updateAll`,
    reset_default_apps: `defaultapplication/reset`,
    dynamicform: `dynamicform`,
    documentSchema: `document-schema`,
    changePassword: '/auth/change-password',
    organization_details_by_guid: '/organization/organization_by_guid',
    document_users: '/document-users',
    document_conversations: '/document-conversations',
    messages: 'messages',
    messages_file: 'messages/upload',
    add_docusign_document_v2: 'docusign/v1/docusign_document',
    notifications: 'notifications',
    verify: 'auth/verify-otp',
    resend_otp: 'auth/send-otp',
    generate_key: 'organization/generate-keys',
    organizations_trusted: 'organizations-trusted',
    generate_signature: 'organization/generate-signature',
    get_txt_records: 'organization/get-txt-records',
    verify_signature: 'organization/verify-signature',
    templates: 'templates',
    send_verification_code: 'user/send-verification-code',
    verifiable_credentials: 'verifiable-credentials',
    verify_verifiable_credentials: 'verifiable-credentials/verify',
    find_doc_by_integra_id: 'document/findByIntergraId',
    check_doc_by_integra_id: 'docusign',
    clear_records: 'messages/clear-records',
    delete_notifications: 'notifications',
    read_notifications: 'notifications/read',
    forget_password: 'user/forgot-password',
    update_forget_Password: 'user/update-password',
    verify_token: 'verify-token',
    decrypt_comm_endpoint: 'document/decrypt-comm-endpoint',
    read_chat: 'messages/read',
    tokenized_owner: 'document/tokenized',
    transfer_owner: 'document/transfer-ownership',
    toggle_blockchain_server: 'settings/toggle-blockchain-server',
    get_signature_validation: 'pdf-utils/getSignatureInfo',
  },
};
