import axios from 'axios';
// config
import { SWITCHBOARD_AUTH_HOST_API } from 'src/config-globals';

// ----------------------------------------------------------------------

const switchboard_axiosInstance = axios.create({ baseURL: SWITCHBOARD_AUTH_HOST_API });

switchboard_axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default switchboard_axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await switchboard_axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  admin: {
    insert: '/api/cloudSB/forms/insert',
    all_forms: '/api/cloudSB/forms/search',
    get_form: '/api/cloudSB/forms/get',
  },
};
