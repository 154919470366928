/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// routes
import { RouterLink } from 'src/routes/components';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
// config
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { Divider, MenuItem } from '@mui/material';
import FormProvider, { RHFPhoneNumber, RHFSelect, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { SHARED_SWITCHBOARD } from 'src/config-globals';
import { UserType } from 'src/config/constants';
import regex from 'src/regex';

// ----------------------------------------------------------------------

const roleArray = [
  {
    label: 'Individual',
    value: UserType.USER,
  },
  {
    label: 'Organization',
    value: UserType.ORG,
  },
];

export default function JwtRegisterView({
  altUI,
  onRegisterButtonClick,
  registerFileAction,
  file,
}) {
  const { register, logout } = useAuthContext();

  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState('');
  const forwardedFile = file ?? null;
  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const password = useBoolean();
  console.log('shared true or false: ', SHARED_SWITCHBOARD);

  const RegisterSchema = Yup.object().shape({
    // firstName: Yup.string().trim().required('First name required'),
    // lastName: Yup.string().trim().required('Last name required'),
    name: Yup.string().trim().required('Full name required'),
    email: Yup.string()
      .trim()
      .required('Email is required')
      .email('Email must be a valid email address'),
    phoneNumber: Yup.string()
      .matches(regex.PHONENUMBER, 'Invalid phone number')
      .required('Phone number is required'),
    password: Yup.string().trim().required('Password is required'),
  });

  const defaultValues = {
    // firstName: '',
    // lastName: '',
    name: '',
    email: '',
    phoneNumber: '',
    role: UserType.USER,
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const isOrganization = watch('role') === UserType.ORG;

  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmit = handleSubmit(async (data) => {
    const payload = {
      name: `${data.name.trim()}`,
      email: data.email.trim(),
      phoneNumber: data.phoneNumber.trim(),
      role: data.role,
      ...(data.organization_name && { organization_name: data.organization_name.trim() }),
      ...(data.organization_url && { organization_url: data.organization_url.trim() }),
      password: data.password.trim(),
    };

    try {
      await register(payload, forwardedFile, registerFileAction, file);
      // await handleLogout();
    } catch (error) {
      console.error(error);
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">Once Registered</Typography>
      <Typography variant="h6">You will automatically logged in.</Typography>
      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> Already have an account? </Typography>

        {altUI ? (
          <Link
            onClick={() => {
              onRegisterButtonClick(false);
            }}
            component={RouterLink}
            variant="subtitle2"
          >
            Sign in
          </Link>
        ) : (
          <Link href={paths.auth.jwt.login} component={RouterLink} variant="subtitle2">
            Sign in
          </Link>
        )}

        {/* <Link href={paths.auth.jwt.login} component={RouterLink} variant="subtitle2">
          Sign in
        </Link> */}
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        color: 'text.secondary',
        mt: 2.5,
        typography: 'caption',
        textAlign: 'center',
      }}
    >
      {'By signing up, I agree to '}
      <Link underline="always" color="text.primary">
        Terms of Service
      </Link>
      {' and '}
      <Link underline="always" color="text.primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack> */}

        <RHFTextField name="name" label="Full Name" />
        <RHFTextField name="email" label="Email address" />
        <RHFPhoneNumber name="phoneNumber" label="Phone Number" />
        {SHARED_SWITCHBOARD && (
          <RHFSelect name="role" label="Role">
            <MenuItem value="" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
              None
            </MenuItem>

            <Divider sx={{ borderStyle: 'dashed' }} />

            {roleArray.map((tags) => (
              <MenuItem key={tags.value} value={tags.value}>
                {tags.label}
              </MenuItem>
            ))}
          </RHFSelect>
        )}

        {isOrganization && (
          <>
            <RHFTextField name="organization_name" label="Organization Name" />
            <RHFTextField name="organization_url" label="Organization URL" />
          </>
        )}

        <RHFTextField
          name="password"
          label="Password"
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ width: 'fit-content', alignSelf: 'center', px: 3 }}
        >
          Create account
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <Stack className="abcd">
      {renderHead}

      {renderForm}

      {/* {renderTerms} */}
    </Stack>
  );
}
