/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { forwardRef, useContext } from 'react';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
// routes
import { useAuthContext } from 'src/auth/hooks';
import Image from 'src/components/image';
import { RouterLink } from 'src/routes/components';
import { ContentContext } from 'src/store/contentContext';
import { demoImgURLHandler, orgURLHandler } from 'src/utils/urlHandler';

// ----------------------------------------------------------------------

const IMAGES = {
  LOGO: '/assets/logos/integra_logo.png',
  LOGO_MINI: '/assets/logos/Integra_QR_Logo.png',
};

const integraSVG = (
  <svg
    width="84"
    height="84"
    style={{ marginTop: '30px' }}
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.8492 11.4414L20.2279 0.144827C20.0818 0.0724135 19.8628 0 19.7167 0C19.4977 0 19.3516 0.0724135 19.1325 0.144827L0.511175 11.4414C0.219075 11.5862 0 11.9483 0 12.3103V29.3999C0 29.7621 0.219075 30.1241 0.511175 30.3413L19.1325 41.8551C19.2786 42 19.4977 42 19.7167 42C19.9358 42 20.1548 41.9276 20.3009 41.8551L38.9222 30.3413C39.2144 30.1241 39.4334 29.7621 39.4334 29.3999V12.3103C39.3604 11.9483 39.2144 11.5862 38.8492 11.4414ZM19.7167 22.5206L18.0372 21.5068L15.9194 22.8103L18.6213 24.4758V39.031L3.21309 29.4724L19.7167 19.4793L21.3963 20.4931L23.514 19.1896L20.8121 17.5965V3.04138L36.2204 12.3827L19.7167 22.5206ZM12.1221 20.4207L14.2398 19.1172L3.21309 12.3827L18.6213 3.04138V17.5965L2.19075 27.5172V14.2655L12.1221 20.4207ZM37.1697 27.5172L27.2383 21.5068L25.1205 22.8103L36.1473 29.4724L20.8121 38.9586V24.4035L37.2427 14.2655V27.5172H37.1697Z"
      fill="url(#paint0_linear_20_189580)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_20_189580"
        x1="5.71764"
        y1="34.7487"
        x2="33.1283"
        y2="7.10666"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0056F5" />
        <stop offset="1" stopColor="#33CCF4" />
      </linearGradient>
    </defs>
  </svg>
);

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const { system_defaults, user } = useAuthContext();
  const { org_data } = useContext(ContentContext);

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const renderLogo = () => {
    if (org_data?.organization_image) {
      return (
        <img
          src={orgURLHandler(org_data?.organization_image)}
          height={50}
          alt={orgURLHandler(org_data?.organization_image)}
        />
      );
    }
    if (user?.organization?.organization_image) {
      return (
        <img
          src={orgURLHandler(user?.organization?.organization_image)}
          height={50}
          alt={orgURLHandler(user?.organization?.organization_image)}
        />
      );
    }
    if (system_defaults) {
      return (
        <img
          src={demoImgURLHandler(system_defaults?.logo)}
          height={50}
          alt={demoImgURLHandler(system_defaults?.logo)}
        />
      );
    }
    if (other?.mini) {
      return <Image alt="integra_logo.png" src={IMAGES.LOGO_MINI} color="primary" width={50} />;
    }

    // NOTE - Use this for imports
    // return integraSVG;
    return <Image alt="integra_logo.png" src={IMAGES.LOGO} color="primary" width={150} />;
  };

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
      {...other}
    >
      {renderLogo()}
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
