import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router';
import auth_axios, { authendpoints } from 'src/utils/authAxios';
import { SET_USER_DATA } from './storeActions';

export const UserContext = React.createContext({
  userData: {},
  get_user_profile: async () => {},
  update_user_profile: async () => {},
  delete_user: async () => {},
});

function userReducer(state, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

    default:
      return state;
  }
}

function UserContextProvider({ children }) {
  const [state, dispatch] = useReducer(userReducer, {
    userData: {},
  });
  const navigate = useNavigate();

  // clear selected form

  const get_user_profile = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.user_profile}/${payload}`);
      dispatch({
        type: SET_USER_DATA,
        payload: response.data,
      });
    } catch (error) {
      enqueueSnackbar('An unexpected error occurred. Please check your data and try again', {
        variant: 'error',
      });
      throw new Error(error);
      // console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update_user_profile = useCallback(async (payload, id) => {
    try {
      const response = await auth_axios.patch(
        `${authendpoints.authnew.user_profile}/${id}`,
        payload
      );

      enqueueSnackbar('Profile updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      throw new Error(error);
      // console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const delete_user = useCallback(async (payload) => {
    try {
      await auth_axios.delete(`${authendpoints.authnew.user_profile}/${payload}`);
      enqueueSnackbar('User deleted successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('An unexpected error occurred. Please try again', {
        variant: 'error',
      });
      throw new Error(error);
      // console.error(error);
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      userData: state.userData,
      get_user_profile,
      update_user_profile,
      delete_user,
    }),
    [state.userData, get_user_profile, update_user_profile, delete_user]
  );

  return <UserContext.Provider value={memoizedValue}>{children}</UserContext.Provider>;
}

export default UserContextProvider;

UserContextProvider.propTypes = {
  children: PropTypes.node,
};
