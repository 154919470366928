export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS';
export const SET_ALL_FORMS = 'SET_ALL_FORMS';
export const SET_SELECTED_FORM = 'SET_SELECTED_FORM';
export const CLEAR_SELECTED_FORM = 'CLEAR_SELECTED_FORM';
export const SET_CONTENTS = 'SET_CONTENTS';
export const SET_ORG = 'SET_ORG';
export const GET_ORG = 'GET_ORG';
export const SET_SINGLE_ORG = 'SET_SINGLE_ORG';
export const SET_ORG_MEMBERS = 'SET_ORG_MEMBERS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_ALL_SCHEMA_FORMS = 'SET_ALL_SCHEMA_FORMS';
